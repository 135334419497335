<template>
    <section>
        <span v-for="(block, blockId) in blocks" :key="blockId">
            <heading v-if="block.element == 'heading' " :text="block.data.text" :justify="block.data.justify" :heading="block.data.heading"></heading>
            <text-paragraph v-else-if="block.element == 'paragraph'" :text="block.data.text" :justify="block.data.justify"/>
            <carousel v-else-if="block.element == 'carousel'" :slides="block.data.slides"/>
        </span>
    </section>
</template>

<script>
import Heading from '../../../components/element/text/heading.vue'
import textParagraph from '../../../components/element/text/paragraph.vue'
import Carousel from '../../../components/element/carousel.vue'
export default {
    name: 'preview-2',
    components: {
        Heading,
        textParagraph,
        Carousel
    },
    data () {
        return {
            ulid:"",
            ulidpage:"01EYNB80RR9K7MDVYPABKCR0HS",
            title:"Section article",
            slugname:"",
            datepublished:"2021-01-01 16:53:01",
            datemodified:"2021-01-01 16:53:01",
            blocks: [
                {
                    order: 1,
                    name: 'carousel',
                    element : "carousel",
                    entitytype: "",
                    data: {
                        slides:
                        [
                            {
                                "carousel-image": "https://resize-parismatch.lanmedia.fr/f/webp/r/625,417,forcex,center-middle/img/var/news/storage/images/paris-match/actu/international/confines-aux-maldives-pour-leur-lune-de-miel-1681136/27406992-1-fre-FR/Confines-aux-Maldives-pour-leur-lune-de-miel.png",
                                "carousel-title": 
                                {
                                    order: 1,
                                    name : "titre",
                                    element: "heading",
                                    data: {
                                        "heading-text": 'Les îles Maldives',
                                        "heading-justify":'center',
                                        "heading-type": 'h3'
                                    }
                                },
                                "carousel-description": {
                                    order: 1,
                                    name :"Message de bienvenue",
                                    element : "paragraph",
                                    data: {
                                        "paragraph-text": "Les Maldives sont un pays tropical de l'océan Indien composé de 26 atolls en forme d'anneaux comprenant plus de 1 000 îles coralliennes !",
                                        "paragraph-justify": 'left'
                                    }
                                },
                                "carousel-button": "",
                                "carousel-button-link": "",
                            }
                        ]
                    }
                }
            ],
        }
    }
}
</script>